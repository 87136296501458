.ptxt {

    font-size: 13px;
    color:  #404040;

}

.ptxtlabel{
    font-weight:  bold;
    display: inline;
    color:  #8888ff;
}
.ptxttext{
    font-weight:  normal;
    display: inline;

}
.ptxtbox{
    background-color: #f6f6f6;
}
